<template>
  <div>

    <b-table
      small
      :fields="fields"
      :items="orders"
      responsive="sm"
    >

      <!-- A custom formatted column -->
      <template #cell(plan)="data">
        {{ data.value.name }}
      </template>

      <!-- A custom formatted column -->
      <template #cell(ss)="data">
        {{ bytesToSize(data.value.u + data.value.d) }}/{{
          bytesToSize(data.value.transfer_enable)
        }}
      </template>

      <!-- A custom formatted column -->
      <template #cell(ss_remain)="data">
        {{
          bytesToSize(
            data.item.ss.transfer_enable - data.item.ss.u - data.item.ss.d
          )
        }}
      </template>

      <!-- A custom formatted column -->
      <template #cell(usage)="data">
        <b-progress
          :value="data.item.ss.u + data.item.ss.d"
          :max="data.item.ss.transfer_enable"
          :variant="getVariant(fixNum(data.item.ss))"
        />
      </template>

      <template #cell(expire_at)="data">
        {{ formatDate(data.value) }}
      </template>

      <template #cell(status)="data">
        <b-badge
          pill
          :variant="getStatusVariant(data.value)"
        >
          {{ fixStatus(data.value) }}
        </b-badge>
      </template>

      <!-- Optional default data cell scoped slot -->
      <template #cell(action)="data">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="info"
          @click="viewOrder(data.item.id)"
        >
          管理
        </b-button>
      </template>
    </b-table>

    <div class="block">
      <b-pagination
        v-model="arg.page"
        :per-page="arg.pageSize"
        hide-goto-end-buttons
        :total-rows="total"
        @click.native="refreshData"
      />
    </div>
  </div>
</template>

<script>
import { bytesToSize } from '@/utils/flow'
import { formatDate } from '@/utils/format-date'
import Ripple from 'vue-ripple-directive'
import useHttp from '@/http/useHttp'
import {
  BTable, BProgress, BBadge, BButton, BPagination,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BProgress,
    BBadge,
    BButton,
    BPagination,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        // A column that needs custom formatting
        { key: 'id', label: 'OrderID', sortable: true },
        // A regular column
        { key: 'plan', label: 'Plan' },
        // A regular column
        { key: 'ss', label: '用量' },
        // A virtual column made up from two fields
        { key: 'ss_remain', label: '剩余' },
        { key: 'expire_at', label: '到期时间', sortable: true },
        { key: 'status', label: '状态', sortable: true },
        'usage',
        'action',
      ],
      orders: [],
      total: 10,
      arg: {
        pageSize: 10,
        page: 1,
      },
      error: {},
    }
  },
  created() {
    this.refreshData()
  },
  methods: {
    fixNum(ss) {
      const num = (((ss.u + ss.d) / ss.transfer_enable) * 100).toFixed(0)
      return parseInt(num, 1)
    },
    getVariant(n) {
      if (n > 90) {
        return 'danger'
      }
      if (n > 60) {
        return 'warning'
      }
      return 'success'
    },
    getStatusVariant(s) {
      if (s === 3) {
        return 'danger'
      }
      return 'success'
    },
    fixStatus(s) {
      if (s === 3) {
        return '失效'
      }
      return '可用'
    },
    handleSizeChange(size) {
      this.arg.pageSize = size
      this.refreshData()
    },
    handleCurrentChange(currentPage) {
      this.arg.page = currentPage
      this.refreshData()
    },
    viewOrder(id) {
      this.$router.push({ name: 'shadowx-order-info', params: { id } })
    },
    refreshData() {
      // console.log('refresh data.....', this.arg)
      useHttp
        .getShadowXOrders({
          params: this.arg,
        })
        .then(res => {
          this.orders = res.data.data.data
          this.total = res.data.data.count
        })
        .catch(error => {
          this.error = error
          // console.log(error)
        })
    },
    bytesToSize,
    formatDate,
  },
}
</script>
