<template>
  <b-row>
    <b-col
      cols="12"
      md="12"
    >
      <b-card title="订单列表">
        <b-card-text>-</b-card-text>
        <OrderList />
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import OrderList from '@/components/shadowx/OrderList.vue'
import {
  BRow, BCol, BCard, BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BRow, BCol, BCard, BCardText, OrderList,
  },
}
</script>

<style>

</style>
